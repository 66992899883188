<template>
  <div class="ps_container patient">
    <div class="ps_header">
      <span class="heading-2">{{ $t("settings.profile.title") }}</span>
      <PrimaryButton :loading="buttonLoading" :text="$t('settings.profile.save')" @onClick="saveChanges" />
    </div>
    <v-divider class="ps_divider"></v-divider>
    <v-card outlined>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <UploadHeader />
      <div class="ps_details personalDetails">
        <span class="heading-4 colorBlack">{{ $t("settings.profile.personalDetails") }}</span>
        <v-row class="ps_row">
          <v-col v-if="isTestingUser" class="ps_input-group d-flex flex-row align-center" cols="12">
            <span class="d-flex align-center text-capitalize">
              <v-icon color="red" small>mdi-information-outline</v-icon>
              &nbsp;Testing account
            </span>
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span> {{ $t("general.inputs.firstName") }} * </span>
            <BaseInput
              v-model="firstName"
              :errors="firstNameErrors"
              class="marginTop12"
              fieldName="firstName"
              @blur="$v.firstName.$touch()"
              @focus="onInputFocus('firstName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.lastName") }} *</span>
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              class="marginTop12"
              fieldName="lastName"
              @blur="$v.lastName.$touch()"
              @focus="onInputFocus('lastName')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.dateOfBirth") }} *</span>
            <div class="marginTop12">
              <DatePicker
                :dense="true"
                :errors="birthDateErrors"
                :height="47"
                :maxDate="new Date().toISOString().substr(0, 10)"
                :value="birthDate"
                @blur="$v.birthDate.$touch()"
                @setDate="changeCurrentDate"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="12" md="4">
            <span>City *</span>
            <v-autocomplete
              :error="Boolean(cityErrors.length)"
              :items="predictionsValue"
              :loading="isLoadingPredictions"
              :search-input.sync="predictionsSearch"
              :value="city"
              class="bd-grey marginTop12 selectStyle"
              clearable
              fieldName="city"
              hide-details
              hide-no-data
              label="Enter city"
              placeholder="Enter city"
              solo
              @blur="$v.city.$touch()"
              @change="onSelectPredictions"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.state") }} *</span>
            <StateSelect
              v-model="state"
              :errors="stateErrors"
              class="marginTop12"
              @blur="$v.state.$touch()"
              @change="stateSelectChange"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.address") }} *</span>
            <BaseInput
              v-model="address"
              :errors="addressErrors"
              :placeholder="$t('general.inputs.address')"
              class="marginTop12"
              fieldName="address"
              @blur="$v.address.$touch()"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Zip Code *</span>
            <BaseInput
              v-model="postalCode"
              :errors="postalCodeErrors"
              class="marginTop12"
              fieldName="postalCode"
              @blur="$v.postalCode.$touch()"
            />
          </v-col>
          <!-- <v-col cols="12" md="4" class="ps_input-group">
            <span>{{ $t("general.inputs.address") }}</span>
            <BaseInput v-model="address" fieldName="address" @focus="onInputFocus('address')" />
          </v-col> -->
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Gender</span>
            <Select v-model="gender" :items="genders" :label="'Genders'" class="marginTop12" fieldName="gender" />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Phone Number</span>
            <PhoneInput
              v-model="phoneNumber"
              :errors="hasPhoneErrors"
              class="marginTop12"
              fieldName="phoneNumber"
              @focus="onInputFocus('phoneNumber')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Home Phone Number</span>
            <PhoneInput
              v-model="homePhoneNumber"
              :errors="hasPhoneErrors"
              class="marginTop12"
              fieldName="homePhoneNumber"
              @focus="onInputFocus('homePhoneNumber')"
            />
          </v-col>
          <v-col class="ps_input-group" cols="12" md="4">
            <span>Timezone</span>
            <Select
              v-model="timeZone"
              :disabled="isAutoTimeZone"
              :items="timezoneOptions"
              class="marginTop12 selectStyleDisable field-details-absolute"
            />
            <Checkbox
              :checked="isAutoTimeZone === true"
              class="marginTop12"
              text="Set time zone automatically"
              @click="onChangeAutoTimezone(!isAutoTimeZone)"
            />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4 fontHelveticaNeue">{{ $t("settings.profile.accountDetails") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="12" md="4">
            <span>{{ $t("general.inputs.email") }}</span>
            <BaseInput v-model="email" :disabled="true" class="marginTop12 selectStyleDisable" fieldName="email" />
          </v-col>
        </v-row>
      </div>
      <ChangePassword class="changePassword" />
      <div class="ps_details">
        <span class="heading-4">{{ $t("settings.profile.generalMedicalInformation") }}</span>
        <v-row class="ps_row">
          <v-col class="ps_input-group" cols="12">
            <div class="flex-row d-flex justify-space-between align-center mb-2">
              <div class="label">Body height</div>
              <OutlinedButton text="Add" @onClick="addBodyHeight" />
            </div>
            <BodyHeightTable />
          </v-col>
          <v-col class="ps_input-group" cols="12">
            <div class="flex-row d-flex justify-space-between align-center mb-2">
              <div class="label">Body weight</div>
              <OutlinedButton text="Add" @onClick="addBodyWeight" />
            </div>
            <BodyWeightTable />
          </v-col>
          <v-col class="ps_input-group" cols="12">
            <div class="flex-row d-flex justify-space-between align-center mb-2">
              <div class="label">Smoking status</div>
              <OutlinedButton text="Add" @onClick="addSmokingBehavior" />
            </div>
            <SmokingBehaviorTable />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <PatientHistoryTable
          id="PMH"
          :items="patientConditions"
          place="visitNotes"
          tableClassName="ph-table_visit-notes_table"
          @change="onPMHChange"
        />
      </div>
      <div class="ps_details">
        <span class="heading-4 ps_margin-bottom">{{ $t("settings.profile.allergies") }}</span>
        <PatientAllergiesView />
        <v-row>
          <v-col :md="8" class="justify-content-end" cols="12">
            <PatientAllergyEdit />
          </v-col>
        </v-row>
      </div>
      <div class="ps_details">
        <span class="heading-4 ps_margin-bottom">Active medications</span>
        <PatientSelfReportedMedication />
      </div>
      <PatientPharmacies />
    </v-card>
    <AddPharmacyDialogFreeText v-model="addPharmacyModal" @close="addPharmacyModal = false" @update="onModalClose" />
    <AddBodyWeight />
    <AddBodyHeight />
    <AddSmokingBehavior />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('general.dialog.confirm')"
      :dialog="confirmBirthDateDialog"
      :text="`The updated date of birth makes ${firstName || ''} ${
        lastName || ''
      } older than 18 years. All individual older than 18 require an independent record and the current proxy access to this record will be removed if you proceed. `"
      confirm-color="primary"
      title="Please confirm the date of birth change change"
      @confirm="onConfirmBirthDate"
      @toggleDialog="confirmBirthDateDialog = false"
    />
  </div>
</template>
<script>
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import moment from "moment-timezone";
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import AddPharmacyDialogFreeText from "@/components/AddPharmacyDialog/FreeText.vue";
import PatientAllergyEdit from "@/components/PatientAllergies/Edit";
import PatientAllergiesView from "@/components/PatientAllergies/View";
import PatientHistoryTable from "@/components/PatientHistoryTable/Table";
import PatientPharmacies from "@/components/PatientPharmacies";
import PatientSelfReportedMedication from "@/components/PatientSelfReportedMedication/Table";
import ChangePassword from "@/components/settings/changePassword";
import UploadHeader from "@/components/settings/uploadHeader";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Checkbox from "@/components/uikit/Checkbox";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { BODY_HEIGHT_ADDED, BODY_WEIGHT_ADDED, SMOKING_BEHAVIOR_ADDED } from "@/eventBuses/monitoringEvents";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { useConditionsStore } from "@/pinia-store/conditions";
import { useObservationsStore } from "@/pinia-store/observations";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { defaultLocale } from "@/utils/constants";
import { calcAge, errToText } from "@/utils/conversion";
import { isValidZip } from "@/utils/validations";
import AddBodyHeight from "@/views/Patient/Monitoring/bodyHeight/Add";
import BodyHeightTable from "@/views/Patient/Monitoring/bodyHeight/Table";
import AddBodyWeight from "@/views/Patient/Monitoring/bodyWeight/Add";
import BodyWeightTable from "@/views/Patient/Monitoring/bodyWeight/Table";
import AddSmokingBehavior from "@/views/Patient/Monitoring/smokingBehavior/Add";
import SmokingBehaviorTable from "@/views/Patient/Monitoring/smokingBehavior/Table";

import PhoneInput from "../shared/PhoneInput";
import StateSelect from "../shared/StateSelect";

const googleAutocompleteService = new window.google.maps.places.AutocompleteService();

const allergiesStore = useAllergiesStore();
const observationStore = useObservationsStore();
export default {
  name: "PatientSettings",
  components: {
    AddPharmacyDialogFreeText,
    Confirm,
    Checkbox,
    AddSmokingBehavior,
    SmokingBehaviorTable,
    BodyHeightTable,
    AddBodyHeight,
    OutlinedButton,
    AddBodyWeight,
    BodyWeightTable,
    PatientSelfReportedMedication,
    PatientPharmacies,
    PatientAllergiesView,
    PatientAllergyEdit,
    PrimaryButton,
    UploadHeader,
    BaseInput,
    ChangePassword,
    Select,
    PatientHistoryTable,
    DatePicker,
    StateSelect,
    PhoneInput,
  },
  validations: {
    firstName: { required },
    lastName: { required },
    birthDate: { required },
    city: { required },
    address: { required },
    state: { required },
    postalCode: { required, isValidZip },
  },
  data: () => ({
    address: "",
    state: "",
    gender: "",
    loading: false,
    buttonLoading: false,
    confirmBirthDate: false,
    confirmBirthDateDialog: false,
    firstName: "",
    postalCode: "",
    birthDate: "",
    originalBirthDate: "",
    city: "",
    lastName: "",
    buttonSelect: true,
    email: "",
    stateModel: "",
    phoneNumber: "",
    homePhoneNumber: "",
    pharmacyModel: "",
    locale: defaultLocale,
    focusedField: null,
    predictions: [],
    predictionsSearch: "",
    predictionsResults: [],
    isLoadingPredictions: false,
    addPharmacyModal: false,
    disabledPharmacyButton: true,
    pharmacyButtonLoading: false,
    isTestingUser: false,
    isAutoTimeZone: true,
    timeZone: "",
    hasPhoneErrors: [],
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useProfileSettingsStore, ["patientSettings", "avatar", "generalInformation", "medicalInformation"]),
    ...mapState(useConditionsStore, ["patientConditions"]),
    timezoneOptions() {
      return this.$moment.tz.names().map((i) => ({ text: i, value: i }));
    },
    predictionsValue() {
      return this.city ? [...this.predictions, this.city] : this.predictions;
    },

    pharmacies() {
      const data = this.patientSettings.careTeam.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      data.unshift({
        text: "+ Add pharmacy",
        value: "add",
      });
      return data;
    },
    genders() {
      const options = Object.entries(this.$t("general.genders")).map(([value, text]) => {
        return {
          text,
          value,
        };
      });
      options.unshift({
        text: this.$t("general.inputs.all"),
        value: "",
      });
      return options;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push(this.$t("validation.required", { field: "City" }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.address") }));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.postalCode.$dirty) return errors;
      (!this.$v.postalCode.required || !this.$v.postalCode.isValidZip) &&
        errors.push(this.$t("validation.required", { field: "Zip Code" }));
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.state") }));
      return errors;
    },
    birthDateErrors() {
      const errors = [];
      if (!this.$v.birthDate.$dirty) return errors;
      !this.$v.birthDate.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.dateOfBirth") }));
      return errors;
    },
    homePhoneNumberErrors() {
      const errors = [];
      if (!this.$v.homePhoneNumber.$dirty) return errors;
      !this.$v.homePhoneNumber.required && errors.push(this.$t("validation.required", { field: "Home phone number" }));
      return errors;
    },
    medications() {
      if (!this.medicalInformation || !this.medicalInformation.medications) {
        return [];
      }
      return this.medicalInformation.medications.map((el) => ({
        ...el,
        startDate: new Date(el.startDate),
      }));
    },
    pmh() {
      if (!this.medicalInformation || !this.medicalInformation.pmh) {
        return [];
      }
      return this.medicalInformation.pmh;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, [
      "toggleBodyWeightFormOpened",
      "clearBodyWeightForm",
      "clearSmokingBehaviorForm",
      "toggleSmokingBehaviorFormOpened",
      "clearBodyHeightForm",
      "toggleBodyHeightFormOpened",
    ]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings"]),
    ...mapActions(useProfileSettingsStore, ["savePatientChanges"]),
    ...mapActions(useObservationsStore, ["getBodyWeight", "getBodyHeight", "getSmokingBehavior"]),
    ...mapPiniaActions(useAllergiesStore, ["getPatientAllergies"]),
    ...mapActions(useProfileSettingsStore, ["savePatientChanges", "changeCareTeam"]),
    ...mapActions(useProfileSettingsStore, ["setMedicalInformation"]),
    ...mapActions(useConditionsStore, [
      "getPatientConditions",
      "addPatientCondition",
      "removePatientCondition",
      "setConditionsPatientId",
    ]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    onChangeAutoTimezone(value) {
      if (value) {
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      this.isAutoTimeZone = value;
    },
    onConfirmBirthDate() {
      this.confirmBirthDate = true;
      this.confirmBirthDateDialog = false;
      this.birthDate = this.requestedBirthDate;
    },
    addBodyWeight() {
      this.clearBodyWeightForm();
      this.toggleBodyWeightFormOpened();
    },
    stateSelectChange(state) {
      this.state = state;
    },
    addBodyHeight() {
      this.clearBodyHeightForm();
      this.toggleBodyHeightFormOpened();
    },
    addSmokingBehavior() {
      this.clearSmokingBehaviorForm();
      this.toggleSmokingBehaviorFormOpened();
    },
    onSelectPredictions(selectedValue) {
      const item = this.predictionsResults.find((item) => item?.terms?.[0].value === selectedValue);
      if (item) {
        this.city = item?.terms?.[0].value || "";
      }
    },
    onInputFocus(field) {
      this.focusedField = field;
      if (this[field] === "") {
        this[field] = this.patientSettings[field];
      }
    },
    changeCurrentDate(newDate) {
      if (calcAge(newDate) < 18 && calcAge(this.originalBirthDate) > 17) {
        this.birthDate = this.originalBirthDate;
        snackBarEventBus.$emit(snackBarEventName, {
          message:
            "Selected Date of birth makes you younger than 18. Please contact customer support for the modification",
          type: "error",
        });
        return;
      }

      if (calcAge(newDate) > 18 && calcAge(this.originalBirthDate) < 18) {
        this.birthDate = this.originalBirthDate;
        this.requestedBirthDate = newDate;
        this.confirmBirthDateDialog = true;
        return;
      }

      this.birthDate = newDate;
    },
    async onPMHChange(value) {
      this.setMedicalInformation({ value, fieldName: "pmh" });
      await this.startSave();
      await this.addPatientCondition(value);
      await this.getPatientConditions();
      await this.endSave();
    },
    async onModalClose() {
      this.patientSettings.careTeam = await PatientsApi.getPatientCareTeam(this.uid);
      this.addPharmacyModal = false;
      this.pharmacyModel = this.pharmacies[1].value;
      this.disabledPharmacyButton = false;
    },
    async saveChanges() {
      this.hasPhoneErrors = [];
      this.$v.$touch();
      if (this.$v.$invalid) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("validation.completeRequiredFields"),
          type: "success",
        });
        return;
      }
      this.buttonLoading = true;
      try {
        await this.savePatientChanges({
          locale: this.locale,
          timeZone: this.timeZone,
          isAutoTimeZone: this.isAutoTimeZone,
          profile: pickBy(
            {
              name: { firstName: this.firstName, lastName: this.lastName },
              postalCode: this.postalCode,
              gender: this.gender,
              city: this.city,
              address: { address: this.address, state: this.state },
              birthDate: moment(this.birthDate).utc(true).format("YYYY-MM-DD"),
              homePhoneNumber: this.homePhoneNumber,
              confirmBirthDate: this.confirmBirthDate,
              phoneNumber: this.phoneNumber,
            },
            identity,
          ),
        });
      } catch (e) {
        let errors = e?.validatorErrors;
        if (errors) {
          if (
            errors.find((err) => err.property === "homePhoneNumber") &&
            errors.find((err) => err.property === "phoneNumber")
          ) {
            snackBarEventBus.$emit(snackBarEventName, {
              message: "Please fill in either mobile or home phone number",
              type: "error",
            });
            this.hasPhoneErrors = ["Please fill in either mobile or home phone number"];
            this.buttonLoading = false;
            return;
          }
        } else {
          snackBarEventBus.$emit(snackBarEventName, {
            message: errToText(e),
            type: "error",
          });
          this.buttonLoading = false;
          return;
        }
      }
      try {
        await this.getPatientSettings();
        this.$root.$i18n.locale = this.locale;
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
      } catch (err) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: err?.message?.[0] || err.message || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
    },
    fillInfo() {
      this.email = this.patientSettings.email;
      this.gender = this.patientSettings.gender;
      this.firstName = this.patientSettings.firstName;
      this.lastName = this.patientSettings.lastName;
      this.phoneNumber = this.patientSettings.phoneNumber;
      this.homePhoneNumber = this.patientSettings.homePhoneNumber;
      this.timeZone = this.patientSettings.timeZone;
      this.isAutoTimeZone = this.patientSettings.isAutoTimeZone;
      this.city = this.patientSettings.city;
      this.state = this.patientSettings.state;
      this.postalCode = this.patientSettings.postalCode;
      this.birthDate = this.patientSettings.birthDate;
      this.originalBirthDate = this.patientSettings.birthDate;
      this.address = this.patientSettings.address;
      this.locale = this.patientSettings.locale;
      this.isTestingUser = this.patientSettings.isTestingUser;
    },
  },
  watch: {
    predictionsSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        const displaySuggestions = (predictions, status) => {
          if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
          }
          this.predictionsResults = predictions;
          this.predictions = predictions.map((pr) => pr?.terms?.[0].value || "");
        };
        googleAutocompleteService.getPlacePredictions(
          { input: val, types: ["(cities)"], componentRestrictions: { country: "us" } },
          displaySuggestions,
        );
      }, 300);
    },
  },
  async mounted() {
    this.loading = true;
    this.fillInfo();
    await this.getPatientSettings();
    this.fillInfo();
    allergiesStore.setPatientId(this.uid);
    observationStore.setPatientId(this.uid);
    try {
      this.setConditionsPatientId(this.uid);
      await Promise.all([
        this.getPatientAllergies(this.uid),
        this.getBodyWeight(),
        this.getBodyHeight(),
        this.getSmokingBehavior(),
        this.getPatientConditions(),
      ]);
    } finally {
      this.loading = false;
    }

    this.$root.$on(SMOKING_BEHAVIOR_ADDED, this.getSmokingBehavior);
    this.$root.$on(BODY_WEIGHT_ADDED, this.getBodyWeight);
    this.$root.$on(BODY_HEIGHT_ADDED, this.getBodyHeight);
  },
  beforeDestroy() {
    this.$root.$off(SMOKING_BEHAVIOR_ADDED, this.getSmokingBehavior);
    this.$root.$off(BODY_WEIGHT_ADDED, this.getBodyWeight);
    this.$root.$off(BODY_HEIGHT_ADDED, this.getBodyHeight);
  },
  // updated() {
  //   if (this.patientSettings.firstName) this.loading = false;
  // },
};
</script>

<style lang="scss">
.fontSize14 {
  font-size: 14px !important;
}

.colorBlack {
  color: #33343e;
}

.marginTop12 {
  margin-top: 12px !important;
}

.personalDetails {
  padding: 23px 26px 36px 26px !important;
}

.ps_input-group {
  //padding-top: 14px !important;
  .v-input {
    .v-input__control .v-input__slot {
      margin-top: 0px !important;
    }
  }
}

.heading-4 {
  font-weight: 500 !important;
}

.changePassword {
  min-width: 100%;
}

.v-input .v-input__slot {
  margin: 0px !important;
}

.v-input {
  flex-direction: column;

  .v-input__control {
    justify-content: flex-end;
  }
}

.ps_btn-wrapper {
  padding-bottom: 0px !important;
  place-self: self-end;
}

.ps_input-group {
  padding-top: 24px;
}

.ps_details {
  padding: 24px 26px 36px 26px !important;
}

.p-schedule-visit__content__required {
  color: var(--v-error-base) !important;
}

.selectStyleDisable {
  background: rgba(238, 239, 247, 0.36);
  /* Light Gray - 2 */
  .v-label {
    border: 1px solid #eeeff7;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    mix-blend-mode: normal;
    opacity: 0.4;
  }
}

.selectStyle {
  .v-label {
    font-weight: 500 !important;
    font-size: 14px;
    color: #33343e !important;
    mix-blend-mode: normal;
  }

  .v-input__slot {
    box-shadow: none !important;
    border: 1px solid #eeeff7;
  }
}

.patient {
  &__allergy-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__allergy-chip {
    width: fit-content;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    align-self: center;
    background-color: #eeeff7 !important;

    &.mobile-size {
      display: inline-flex;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      margin-left: 10px;
      color: #d2d3e1;

      &:before {
        color: #d2d3e1;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .buttonSelect {
    max-width: 100px;
  }
  .ps_input-group {
    padding-top: 19px !important;
  }
}
</style>
