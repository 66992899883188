<template>
  <div class="settings">
    <PatientSettings />
  </div>
</template>

<script>
import PatientSettings from "@/components/settings/patient";
export default {
  name: "patientSettings",
  components: {
    PatientSettings,
  },
};
</script>
<style lang="scss">
.settings {
  max-width: 100vw;
}
</style>
