<template>
  <div class="ps_details pharmacy-wrap">
    <div class="flex-row d-flex justify-space-between align-center mb-2">
      <div class="heading-4">My Pharmacies</div>
      <OutlinedButton text="Add" @onClick="toggleDialog" />
    </div>
    <v-row v-if="pharmacies" class="mt-2">
      <v-col v-for="item in pharmacies" :key="item.id" cols="12" md="4">
        <v-card>
          <v-card-title flat>
            <v-toolbar-title>
              {{ item.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="red" height="26" icon width="26" @click="onDelete(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <div class="item-description-wrap">
            <v-card-text>{{ item.address || "--" }}</v-card-text>
            <v-card-text>{{ item.city || "--" }}</v-card-text>
            <v-card-text>{{ item.state || "--" }}</v-card-text>
            <v-card-text>{{ item.zipCode || "--" }}</v-card-text>
          </div>
          <v-card-actions>
            <Checkbox :checked="item.status === 'active'" text="Set as default" @click="setPrimary(item.id)" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <PharmacySelectTextFree
      :opened="addPharmacyModal"
      title="Add Pharmacy"
      @onClose="toggleDialog"
      @onSelect="onSelectPharmacyFreeText"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Checkbox from "@/components/uikit/Checkbox";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PharmacySelectTextFree from "@/components/VisitNotes/PharmacySelectTextFree.vue";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { usePatientStore } from "@/pinia-store/patient";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

export default {
  name: "PatientPharmacies",
  components: { PharmacySelectTextFree, OutlinedButton, Checkbox },
  data: () => ({
    addPharmacyModal: false,
  }),
  watch: {
    "$store.state.patient.patient": {
      immediate: true,
      deep: true,
      async handler(val) {
        if (val?.id) {
          await this.getPatientPharmacies(val?.id);
        }
      },
    },
  },
  computed: {
    ...mapState(usePatientStore, ["pharmacies"]),
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["createPatientSettingsCareTeam"]),
    ...mapActions(usePatientStore, [
      "addPatientPharmacy",
      "getPatientPharmacies",
      "removePatientPharmacy",
      "setPatientPharmacyActive",
    ]),
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    toggleDialog() {
      this.addPharmacyModal = !this.addPharmacyModal;
    },
    async onSelectPharmacyFreeText(item) {
      await this.startSave();
      this.addPharmacyModal = false;
      await this.createPatientSettingsCareTeam({ ...item, type: "pharmacy" });
      await this.getPatientPharmacies();
      this.addPharmacyModal = false;
      await this.endSave();
    },
    async onSelectPharmacy(item) {
      await this.startSave();
      this.addPharmacyModal = false;
      await this.addPatientPharmacy({ pharmacyId: item.id, primary: false });
      await this.endSave();
    },
    async setPrimary(id) {
      await this.startSave();
      await this.setPatientPharmacyActive(id);
      await this.getPatientPharmacies();
      await this.endSave();
    },
    async onDelete(id) {
      await this.startSave();
      await this.removePatientPharmacy(id);
      await this.endSave();
    },
  },
  async mounted() {
    if (this.patient?.id) await this.getPatientPharmacies();
  },
};
</script>

<style lang="scss" scoped>
.edit-wrap {
  display: flex;
  align-items: center;
  gap: 10px;

  .v-input__slot {
    margin-top: 0 !important;
  }

  .add-medication {
    background-color: #fff !important;
    flex-basis: 200px;
  }
}

.pharmacy-wrap {
  .v-card {
    border: 1px solid #eeeff7;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .item-description-wrap {
    .v-card__text {
      padding-left: 26px;
    }
  }

  .v-card__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #25233a;
    mix-blend-mode: normal;
    opacity: 0.5;
    padding: 5px 10px;
  }

  .v-card__title {
    padding-left: 26px;
    padding-bottom: 2px;
    flex-wrap: nowrap;

    .v-toolbar__title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #33343e;
      padding-left: 0;
      word-break: break-word;
      white-space: break-spaces;
    }
  }

  .v-card__actions {
    margin-top: auto;
    padding-left: 18px;
  }
}
</style>
