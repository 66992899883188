<template>
  <div class="medication-table">
    <div class="list-wrap">
      <MedicalItem v-for="item in selfReportedMedicationOrdered" :key="item.id" :item="item" />
    </div>
    <div class="edit-wrap">
      <v-autocomplete
        v-model="name"
        :errors="nameErrors"
        :items="searchResultsFormatted"
        :label="$t('visits.medical.medicationPlaceholder')"
        :loading="isLoadingDrugs"
        :placeholder="$t('visits.medical.medicationPlaceholder')"
        :search-input.sync="search"
        cache-items
        className="bd-grey"
        clearable
        fieldName="name"
        hide-details
        hide-no-data
        solo
        @change="onSelectMedication"
      ></v-autocomplete>
    </div>
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { TerminologyApi } from "@/api/terminology";
import MedicalItem from "@/components/Medications/MedicationlItem";
import { useAuthStore } from "@/pinia-store/auth";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "PatientSelfReportedMedication",
  components: {
    MedicalItem,
  },
  data: () => ({
    name: "",
    frequencyList: [],
    frequencySearch: null,
    drugs: [],
    drugsResults: [],
    searchResultsFormatted: [],
    search: "",
    isLoadingDrugs: false,
    hideDosageFields: true,
    selectedItem: {},
  }),
  validations: {
    name: { required },
    startDate: { required },
  },
  watch: {
    // todo: change wathc
    "$store.state.patient.patient": {
      immediate: true,
      async handler(val) {
        if (val && val.id) {
          await this.getSelfReportedMedication();
        }
      },
    },
    search(val) {
      if (!val || val === this.name || val?.length < 3) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        // DosespotApi.searchMedication(val).then((values) => {
        TerminologyApi.searchDrugs(val).then((values) => {
          this.isLoadingDrugs = false;

          this.searchResults = [...values, { name: val, quantity: "" }];
          this.searchResultsFormatted = [`${val} `, ...values.map((it) => `${it.name} ${it.quantity || ""}`)];
        });
      }, 300);
    },
  },
  computed: {
    ...mapState(usePatientStore, ["selfReportedMedication", "patient"]),
    ...mapState(useAuthStore, ["uid"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("visits.medical.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    typesList() {
      const medicationTypes = this.$t("general.medicationTypes");
      if (!medicationTypes) {
        return [];
      }
      return Object.keys(medicationTypes).map((key) => ({ text: medicationTypes[key], value: key }));
    },
    selfReportedMedicationOrdered() {
      return orderBy(this.selfReportedMedication, ["status"]);
    },
  },
  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(usePatientStore, ["getSelfReportedMedication", "addSelfReportedMedication"]),
    async onSelectMedication(selectedValue) {
      const item = this.searchResults.find((result) => `${result.name} ${result.quantity || ""}` === selectedValue);
      if (item) {
        this.startSave();
        await this.addSelfReportedMedication({ ...item });
        this.endSave();
      }
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
  },
};
</script>

<style lang="scss">
.medication-table {
  .v-input__slot {
    border: 1px solid rgba(51, 52, 62, 0.4) !important;
  }

  .list-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    max-height: 500px;
    overflow: auto;
    padding-right: 10px;
  }

  &__search {
    padding-top: 0;
  }

  &__title {
    font-weight: bold;
    padding: 20px 20px 0 20px;
    color: $primaryblack2;
  }

  .edit-wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    .v-input__slot {
      margin-top: 0 !important;
      box-shadow: none !important;
      border: 1px solid #eeeff7 !important;
    }

    .add-medication {
      background-color: #fff !important;
      flex-basis: 200px;
    }
  }
}
</style>
