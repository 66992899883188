<template>
  <v-dialog :value="value" max-width="710" @input="$emit('value')" @click:outside="onClose">
    <v-card class="card">
      <v-icon class="card-close rounded" color="icon" size="medium" @click="onClose"> mdi-close</v-icon>
      <v-card-title class="card-title"> Add New Pharmacy</v-card-title>
      <form novalidate v-on:submit.prevent="submit">
        <v-row class="align-center">
          <v-col class="col-2 pb-0"
            ><label for="pharmacyName">Pharmacy <sup class="red--text">*</sup></label></v-col
          >
          <v-col class="col-10 pb-0">
            <BaseInput
              id="pharmacyName"
              v-model="pharmacyName"
              :errors="pharmacyNameErrors"
              fieldName="Pharmacy"
              placeholder="Enter Name"
              @blur="$v.firstName.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col class="col-2 pb-0"
            ><label for="pharmacyCity">City <sup class="red--text">*</sup></label></v-col
          >
          <v-col class="col-10 pb-0">
            <BaseInput
              id="pharmacyCity"
              v-model="pharmacyCity"
              :errors="pharmacyCityErrors"
              fieldName="City"
              placeholder="Enter City"
              @blur="$v.firstName.$touch()"
              @change="onFieldChange"
            />
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col class="col-2"
            ><label for="pharmacyAddress">Address <sup class="red--text">*</sup></label></v-col
          >
          <v-col class="col-10">
            <BaseInput
              id="pharmacyAddress"
              v-model="pharmacyAddress"
              :errors="pharmacyAddressErrors"
              fieldName="Address"
              placeholder="Enter Address"
              @blur="$v.firstName.$touch()"
              @change="onFieldChange"
            />
          </v-col>
        </v-row>
        <v-card-actions class="actions">
          <v-btn class="btn btn-cancel" color="#E5E5E5" outlined @click="onClose">
            <span>{{ $t("general.dialog.cancel") }}</span>
          </v-btn>
          <v-btn :disabled="disabled" class="btn" color="primary" type="submit">
            <v-progress-circular v-if="loading" color="white" indeterminate size="25" width="3" />
            <span v-else>Add Pharmacy</span>
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "pinia";
import { required } from "vuelidate/lib/validators";

import BaseInput from "@/components/uikit/BaseInput";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

export default {
  name: "AddPharmacyDialogFreeText",
  props: {
    value: Boolean,
  },
  components: {
    BaseInput,
  },
  data() {
    return {
      loading: false,
      pharmacyName: "",
      pharmacyCity: "",
      pharmacyAddress: "",
    };
  },
  validations: {
    pharmacyName: { required },
    pharmacyCity: { required },
    pharmacyAddress: { required },
  },
  computed: {
    pharmacyNameErrors() {
      const errors = [];
      if (!this.$v.pharmacyName.$dirty) return errors;
      !this.$v.pharmacyName.required && errors.push(this.$t("validation.required", { field: "Pharmacy" }));

      return errors;
    },
    pharmacyCityErrors() {
      const errors = [];
      if (!this.$v.pharmacyCity.$dirty) return errors;
      !this.$v.pharmacyCity.required && errors.push(this.$t("validation.required", { field: "City" }));

      return errors;
    },
    pharmacyAddressErrors() {
      const errors = [];
      if (!this.$v.pharmacyAddress.$dirty) return errors;
      !this.$v.pharmacyAddress.required && errors.push(this.$t("validation.required", { field: "City" }));

      return errors;
    },
    disabled() {
      return !this.pharmacyName && !this.pharmacyAddress && !this.pharmacyCity;
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["createPatientSettingsCareTeam"]),
    onClose() {
      this.$emit("value");
      this.$emit("input", false);
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async submit() {
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      try {
        await this.createPatientSettingsCareTeam({
          name: this.pharmacyName,
          address: this.pharmacyAddress,
          city: this.pharmacyCity,
          type: "pharmacy",
        });
      } catch (err) {
        let message = err.message || err;
        if (err.isAxiosError) {
          message = err.response.data.message;
        }
        snackBarEventBus.$emit(snackBarEventName, { message, type: "error" });
      }
      this.$emit("update");
      snackBarEventBus.$emit(snackBarEventName, {
        message: "Pharmacy has been successfully added",
        type: "success",
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  position: relative;

  &-close {
    position: absolute !important;
    top: 17px;
    right: 16px;
    width: 0.1rem;
    height: 0.1rem;
  }
}

.input {
  margin: 10px 20px;
}

.btn {
  text-transform: capitalize;
  font-weight: 600;

  &-cancel {
    span {
      color: #ccccce;
    }
  }
}

.actions {
  justify-content: flex-end;
  display: flex;
}
</style>
